import { mapActions } from 'pinia'
import { useIndustryStore } from '@/stores'
import ScrollableTable from '@/modules/industry/components/IndustryOfferTable/ScrollableTable.vue'
import BasePageLayout from '@/components/base/BasePageLayout.vue'
import { MUTATION_DELETE_INDUSTRY_OFFERS, MUTATION_REPROCESS_INDUSTRY_OFFER } from '@/modules/industry/graphql'
import { ENUM_ACTIONS, ENUM_STATUS, ENUM_MODALITY, ENUM_SELECTION_MODE } from '@/modules/industry/enums'
import { getOfferType } from '@/modules/industry/helpers/offerType.js'

export const manageIndustryOffers = {
  components: {
    StatusInTable: () => import('@/modules/industry/components/IndustryOfferTable/StatusInTable'),
    MassSelectionButton: () => import('@/modules/industry/components/IndustryOfferTable/MassSelectionButton'),
    DeleteOffersButton: () => import('@/modules/industry/components/IndustryOfferTable/DeleteOffersButton'),
    ReprocessOffersButton: () => import('@/modules/industry/components/IndustryOfferTable/ReprocessOffersButton'),
    SelectOffersToEdit: () => import('@/modules/industry/components/IndustryOfferTable/SelectOffersToEdit'),
    HeaderFilter: () => import('@/modules/industry/components/Filters/HeaderFilter.vue'),
    ContextMenu: () => import('@/modules/industry/components/IndustryOfferTable/ContextMenu'),
    ExportIndustryOffersData: () => import('@/modules/industry/components/ExportIndustryOffersData.vue'),
    ScrollableTable,
    BasePageLayout
  },
  data: () => ({
    ENUM_ACTIONS,
    ENUM_STATUS,
    ENUM_MODALITY,
    ENUM_SELECTION_MODE,
    loading: false,
    search: '',
    debounceSearch: '',
    options: {
      page: 1,
      itemsPerPage: 50
    },
    offers: [],
    timer: null,
    dataCount: 0,
    showMassSelection: false,
    massSelectionMode: ENUM_SELECTION_MODE.NONE,
    selectedOffers: [],
    editKey: 'canBeEdited',
    deleteKey: 'canBeDeleted',
    reprocessKey: 'canBeReprocessed'
  }),
  computed: {
    actionButtonLabel() {
      const message = this.selectedOffers.length <= 1 ? 'oferta' : 'ofertas'
      let action = ''
      switch (this.massSelectionMode) {
        case ENUM_SELECTION_MODE.EDIT:
          action = 'Editar'
          break
        case ENUM_SELECTION_MODE.REPROCESS:
          action = 'Reprocessar'
          break
        case ENUM_SELECTION_MODE.DELETE:
          action = 'Remover'
          break
      }
      return `${action} ${this.selectedOffers.length} ${message}`
    },
    hasNoOffersSelected() {
      return !this.selectedOffers.length
    },
    selectedIndustryIds() {
      return this.filters.industries.map(industry => industry._id)
    },
    isEditButtonVisible() {
      return this.showMassSelection && this.massSelectionMode === ENUM_SELECTION_MODE.EDIT
    },
    isReprocessButtonVisible() {
      return this.showMassSelection && this.massSelectionMode === ENUM_SELECTION_MODE.REPROCESS
    },
    isDeleteButtonVisible() {
      return this.showMassSelection && this.massSelectionMode === ENUM_SELECTION_MODE.DELETE
    },
    canBeSelectedKey() {
      if (this.massSelectionMode === ENUM_SELECTION_MODE.EDIT) return this.editKey
      else if (this.massSelectionMode === ENUM_SELECTION_MODE.REPROCESS) return this.reprocessKey
      else if (this.massSelectionMode === ENUM_SELECTION_MODE.DELETE) return this.deleteKey
    }
  },
  mounted() {
    this.resetSelectedOffersGroup()
    this.resetOffer()
  },
  watch: {
    search(newValue, _) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.options.page = 1
        this.debounceSearch = newValue
      }, 300)
    }
  },
  methods: {
    ...mapActions(useIndustryStore, ['setSelectedOffersGroup', 'resetOffer', 'resetSelectedOffersGroup']),

    canBeDeleted(offer) {
      if (offer.action === ENUM_ACTIONS.DELETE && offer.status === ENUM_STATUS.SUCCESS) return false
      return true
    },
    canBeEdited(offer) {
      if (offer.offer_type === 'cross_sell' || offer.offer_type === 'dynamic_rule') return false
      const canBeEditedConditions = [
        offer.action === ENUM_ACTIONS.CREATE && offer.status === ENUM_STATUS.SUCCESS,
        offer.action === ENUM_ACTIONS.CREATE && offer.status === ENUM_STATUS.MISSING_PRODUCTS,
        offer.action === ENUM_ACTIONS.DELETE && offer.status === ENUM_STATUS.ERROR,
        offer.action === ENUM_ACTIONS.UPDATE && offer.status === ENUM_STATUS.SUCCESS,
        offer.action === ENUM_ACTIONS.UPDATE && offer.status === ENUM_STATUS.MISSING_PRODUCTS
      ]
      return canBeEditedConditions.some(condition => condition)
    },
    canBeReprocessed(offer) {
      return offer.status === ENUM_STATUS.ERROR
    },

    refreshTable() {
      this.$apollo.queries.offers.refetch()
    },

    openMassSelection() {
      this.selectedOffers = []
      this.showMassSelection = true
    },

    closeMassSelection() {
      this.selectedOffers = []
      this.showMassSelection = false
      this.massSelectionMode = ''
    },

    editMassSelection() {
      this.openMassSelection()
      this.massSelectionMode = ENUM_SELECTION_MODE.EDIT
    },

    reprocessMassSelection() {
      this.openMassSelection()
      this.massSelectionMode = ENUM_SELECTION_MODE.REPROCESS
    },

    deleteMassSelection() {
      this.openMassSelection()
      this.massSelectionMode = ENUM_SELECTION_MODE.DELETE
    },

    addCommonFields(offer) {
      return {
        ...offer,
        [this.editKey]: this.canBeEdited(offer),
        [this.deleteKey]: this.canBeDeleted(offer),
        [this.reprocessKey]: this.canBeReprocessed(offer),
        canBeSelected: this.canBeDeleted(offer) || this.canBeEdited(offer) || this.canBeReprocessed(offer),
        offer_type: getOfferType(offer.offer_type),
        halfOpacity: offer.action === ENUM_ACTIONS.DELETE && offer.status === ENUM_STATUS.SUCCESS,
        existsOnRetailer: offer.offers_schedule_id && !(offer.action === ENUM_ACTIONS.DELETE && offer.status === ENUM_STATUS.SUCCESS)
      }
    },

    groupOffers(modality) {
      const groupedOffers = this.selectedOffers.reduce((acc, offer) => {
        const name = modality === ENUM_MODALITY.MERCATRADE ? offer.title : offer.name
        const offerType = offer.offer_type
        const existingGroup = acc.find(group => group.groupKey === `${name}-${offerType}`)

        if (existingGroup) {
          existingGroup.offers.push(offer)
        } else {
          acc.push({
            name,
            offerType,
            modality,
            groupKey: `${name}-${offerType}`,
            offers: [offer]
          })
        }

        return acc
      }, [])
      return groupedOffers
    },

    openEditDag() {
      return window.open(process.env.VUE_APP_INDUSTRY_OFFER_UPDATE, '_blank')
    },

    openDeleteDag() {
      return window.open(process.env.VUE_APP_INDUSTRY_OFFER_DELETE, '_blank')
    },

    handleSingleEdit(modality, item) {
      this.editSelectedOffers(modality, {
        name: item.name || item.title,
        offerType: item.offer_type,
        modality,
        groupKey: `${item.name || item.title}-${item.offer_type}`,
        offers: [item]
      })
    },

    editSelectedOffers(modality, offerGroup) {
      this.setSelectedOffersGroup(offerGroup)
      const routeName = modality === ENUM_MODALITY.MERCATRADE ? 'edit-mercatrade' : 'edit-mercapromo'
      this.$router.push({ name: routeName })
    },

    async handleSingleReprocess(modality, item) {
      this.selectedOffers = [item]
      await this.reprocessSelectedOffers(modality)
      this.selectedOffers = []
    },

    async reprocessSelectedOffers(modality) {
      const offerIds = this.selectedOffers.map(offer => offer._id)

      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_REPROCESS_INDUSTRY_OFFER,
          variables: {
            input: { modality, offer_ids: offerIds }
          },
          context: {
            uri: this.$microservicesUrls['accounts'],
            headers: {
              authorization: localStorage.getItem('session_id')
            }
          }
        })
        this.$alert({
          alert_message: 'Oferta(s) marcada(s) para ser(em) processada(s) com sucesso.',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.refreshTable()
      } catch (error) {
        this.$snackbar({ message: 'Erro ao marcar oferta(s) para ser(em) re-processada(s).', snackbarColor: 'error' })
        console.log(error)
      } finally {
        this.closeMassSelection()
        this.loading = false
      }
    },

    async handleSingleDelete(modality, item) {
      this.selectedOffers = [item]
      await this.deleteSelectedOffers(modality)
      this.selectedOffers = []
    },

    async deleteSelectedOffers(modality) {
      const offerIds = this.selectedOffers.map(offer => offer._id)

      let confirmMessage = `Deseja realmente excluir as ${offerIds.length} ofertas selecionadas?`
      if (offerIds.length === 1) {
        const offerName = modality === ENUM_MODALITY.MERCAPROMO ? this.selectedOffers[0].name : this.selectedOffers[0].title
        confirmMessage = `Deseja realmente excluir a oferta ${offerName}?`
      }
      const confirmation = await this.$confirm({
        title: 'Confirmar exclusão',
        message: confirmMessage,
        confirmText: 'Excluir',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmation) {
        return
      }

      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_DELETE_INDUSTRY_OFFERS,
          variables: {
            input: { modality, offer_ids: offerIds }
          },
          context: {
            uri: this.$microservicesUrls['accounts'],
            headers: {
              authorization: localStorage.getItem('session_id')
            }
          }
        })
        this.$alert({
          alert_message: 'Oferta(s) marcada(s) para ser(em) excluída(s) com sucesso.',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.refreshTable()
      } catch (error) {
        this.$alert({
          alert_message: 'Erro ao marcar oferta(s) para ser(em) excluída(s).',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle',
          action: () => {
            const { errors } = error.networkError.result
            this.$errorDialog({
              title: errors.length > 1 ? 'Detalhes dos erros' : 'Detalhes do erro',
              errors: errors
            })
          },
          action_label: 'Ver detalhes'
        })
      } finally {
        this.closeMassSelection()
        this.loading = false
      }
    }
  }
}
