<template>
  <div>
    <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
    <div v-dragscroll.x class="scrollable-table-container">
      <table class="scrollable-table">
        <thead>
          <tr>
            <th
              key="header-first"
              :class="{ 'fixed-column': true, 'cursor-default': !showSelect, 'cursor-pointer': showSelect }"
              :style="{ width: headersFirstElement.width ? headersFirstElement.width : 'auto' }"
              @click="toggleAll"
            >
              <div class="row-with-select" :style="{ width: headersFirstElement.width ? headersFirstElement.width : 'auto' }">
                <v-simple-checkbox
                  v-if="showSelect"
                  :value="allItemsSelected"
                  :disabled="noneCanBeSelected"
                  :indeterminate="atLeastOneItemIsSelected"
                  color="primary"
                  @input="toggleAll"
                />
                <slot :name="`header.${headersFirstElement.value}`" :header="headersFirstElement">
                  {{ headersFirstElement.text }}
                </slot>
              </div>
            </th>
            <th v-for="(header, index) in headersWithoutFirstAndLastElement" :key="`header-${index}`" :style="{ width: header.width ? header.width : 'auto' }">
              <slot :name="`header.${header.value}`" :header="header">
                {{ header.text }}
              </slot>
            </th>
            <th key="header-last" class="fixed-column" :style="{ width: headersLastElement.width ? headersLastElement.width : 'auto' }">
              <slot :name="`header.${headersLastElement.value}`" :header="headersLastElement">
                {{ headersLastElement.text }}
              </slot>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, itemIndex) in items"
            :key="`item-row-${itemIndex}`"
            :class="{ 'opaque-text-color': item.halfOpacity || (selectableKey && !item[selectableKey]) }"
          >
            <td
              key="item-first"
              :class="{
                'fixed-column': true,
                'cursor-default': !showSelect || !item[selectableKey],
                'cursor-pointer': showSelect && item[selectableKey]
              }"
              :style="{ width: headersFirstElement.width ? headersFirstElement.width : 'auto' }"
              @click="toggleSelection(item)"
            >
              <div class="row-with-select">
                <v-simple-checkbox
                  v-if="showSelect"
                  :value="isItemSelected(item[itemKey])"
                  :disabled="selectableKey && !item[selectableKey]"
                  color="primary"
                  @input="toggleSelection(item)"
                />
                <slot :name="`item.${headersFirstElement.value}`" :item="item">
                  <span>{{ item[headersFirstElement.value] }}</span>
                </slot>
              </div>
            </td>

            <td
              v-for="(header, headerIndex) in headersWithoutFirstAndLastElement"
              :key="`item-${headerIndex}`"
              :style="{ width: header.width ? header.width : 'auto' }"
            >
              <slot :name="`item.${header.value}`" :item="item">
                <span>{{ item[header.value] }}</span>
              </slot>
            </td>

            <td key="item-last" class="fixed-column" :style="{ width: headersLastElement.width ? headersLastElement.width : 'auto' }">
              <slot :name="`item.${headersLastElement.value}`" :item="item">
                <span>{{ item[headersLastElement.value] }}</span>
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="items.length <= 0" class="no-data opaque-text-color">
      <span>{{ loading ? 'Carregando' : 'Não há dados' }}</span>
    </div>
    <div class="pagination-footer">
      <span>Itens por página: </span>
      <div class="select-items-per-page">
        <v-select :value="options.itemsPerPage" dense hide-details :items="footerProps.itemsPerPageOptions" @change="changeSelectedItemsPerPage($event)">
          <template slot="selection" slot-scope="data">
            <span class="item-text">{{ data.item }}</span>
          </template>
        </v-select>
      </div>
      <span v-if="items.length > 0">
        {{ firstItemNumberOfCurrentPage | localeString }}-{{ lastItemNumberOfCurrentPage | localeString }} de {{ serverItemsLength | localeString }}
      </span>
      <span v-else>-</span>
      <v-btn icon small :disabled="this.options.page === 1 || items.length <= 0" @click="previousPage">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn icon small :disabled="lastPage || items.length <= 0" @click="nextPage">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollableTable',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    selectableKey: {
      type: String,
      default: null
    },
    serverItemsLength: {
      type: Number,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    footerProps: {
      type: Object,
      default: () => ({
        itemsPerPageOptions: [10, 50, 100]
      })
    },
    options: {
      type: Object,
      default: () => ({
        page: 1,
        itemsPerPage: 50,
        sortBy: '',
        sortDesc: 'DESC'
      })
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedItems: []
  }),
  computed: {
    headersFirstElement() {
      return this.headers[0]
    },
    headersWithoutFirstAndLastElement() {
      let formattedHeaders = this.headers.slice(1, -1)
      return formattedHeaders
    },
    headersLastElement() {
      return this.headers[this.headers.length - 1]
    },
    firstItemNumberOfCurrentPage() {
      return (this.options.page - 1) * this.options.itemsPerPage + 1
    },
    lastItemNumberOfCurrentPage() {
      return Math.min(this.options.page * this.options.itemsPerPage, this.serverItemsLength)
    },
    lastPage() {
      return this.options.page === Math.ceil(this.serverItemsLength / this.options.itemsPerPage)
    },
    noneCanBeSelected() {
      if (this.selectableKey) {
        return this.items.every(item => !item[this.selectableKey])
      }
      return false
    },
    allItemsSelected() {
      if (this.selectableKey) {
        const selectableItems = this.items.filter(item => item[this.selectableKey])
        if (selectableItems.length === 0) return false
        return selectableItems.every(item => this.selectedItems.some(selectedItem => selectedItem[this.itemKey] === item[this.itemKey]))
      }
      if (this.items.length === 0) return false
      return this.items.every(item => this.selectedItems.some(selectedItem => selectedItem[this.itemKey] === item[this.itemKey]))
    },
    atLeastOneItemIsSelected() {
      if (this.selectableKey) {
        const selectableItems = this.items.filter(item => item[this.selectableKey])
        return selectableItems.some(item => this.isItemSelected(item[this.itemKey])) && !this.allItemsSelected
      }
      return this.items.some(item => this.isItemSelected(item[this.itemKey])) && !this.allItemsSelected
    }
  },
  watch: {
    showSelect() {
      this.clearSelection()
    }
  },
  mounted() {
    this.selectedItems = this.value
  },
  methods: {
    isItemSelected(id) {
      const isSelected = this.selectedItems.some(item => item[this.itemKey] === id)
      return isSelected
    },
    previousPage() {
      this.$emit('update:options', { ...this.options, page: this.options.page - 1 })
    },
    nextPage() {
      this.$emit('update:options', { ...this.options, page: this.options.page + 1 })
    },
    changeSelectedItemsPerPage(newValue) {
      this.$emit('update:options', { ...this.options, page: 1, itemsPerPage: newValue })
    },
    clearSelection() {
      this.selectedItems = []
      this.$emit('change', this.selectedItems)
    },
    toggleAll() {
      if (!this.showSelect || this.noneCanBeSelected) return
      if (this.allItemsSelected) {
        this.selectedItems = this.selectedItems.filter(selectedItem => !this.items.some(item => item[this.itemKey] === selectedItem[this.itemKey]))
      } else {
        const newSelections = this.items.filter(item => this.canBeSelected(item) && !this.isItemSelected(item[this.itemKey]))
        this.selectedItems = [...this.selectedItems, ...newSelections]
      }
      this.$emit('change', this.selectedItems)
    },
    canBeSelected(item) {
      return this.showSelect && (!this.selectableKey || item[this.selectableKey])
    },
    toggleSelection(item) {
      if (!this.canBeSelected(item)) return
      const itemIndex = this.selectedItems.findIndex(selectedItem => selectedItem[this.itemKey] === item[this.itemKey])
      if (itemIndex >= 0) {
        this.selectedItems.splice(itemIndex, 1)
      } else {
        this.selectedItems.push(item)
      }
      this.$emit('change', this.selectedItems)
    }
  }
}
</script>

<style lang="scss" scoped>
.scrollable-table-container {
  overflow-x: auto;
  user-select: none;
  cursor: grab;
}

.scrollable-table {
  margin-bottom: 16px;
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.scrollable-table th {
  background-color: #f6f6f8;
  color: #31363b;
  font-weight: 700;
  height: 50px;
}

.scrollable-table th,
.scrollable-table td {
  padding: 8px 16px;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fixed-column {
  position: sticky;
  background: white;
  z-index: 1;
}

.fixed-column::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: #f3f3f3;
}

.fixed-column::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background-color: #f3f3f3;
}

.fixed-column:first-child {
  left: 0;
}

.fixed-column:last-child {
  right: 0;
}

.row-with-select {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.pagination-footer {
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  gap: 24px;

  .select-items-per-page {
    width: 50px;

    .item-text {
      font-size: 12px;
    }
  }
}

.no-data {
  padding: 16px;
  display: flex;
  justify-content: center;
}

.opaque-text-color {
  color: #a1a1a1;
}

tr {
  background: white;
}

th:first-child {
  padding-left: 16px;
}

td:first-child {
  padding-left: 16px;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
</style>
