export function getOfferType(offer_type) {
  switch (offer_type) {
    case 'takepay':
      return 'Leve & Pague'
    case 'nextbuycashback':
    case 'cashback':
      return 'Cashback'
    case 'general':
      return 'Desconto Absoluto'
    case 'percentage':
      return 'Desconto Percentual'
    case 'cross_sell':
      return 'Cross Sell'
    case 'dynamic_rule':
      return 'Voucher Digital'
  }
}
